import type {
  DocumentCategory,
  Message,
  MessageRole,
  MessageSource,
} from "@prisma/client";
import { create } from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";

interface PublicChatState {
  messages: Array<Pick<Message, "createdAt" | "text" | "role">>;
  categories: DocumentCategory[];
  rateLimitMessagesIndex: number | null;
  selectCategories: (categories: DocumentCategory[]) => void;
  addMessage: (
    data: {
      text: string;
      role: MessageRole;
      sources?: Array<Pick<MessageSource, "id" | "title" | "url">>;
    },
    options?: { rateLimited?: boolean }
  ) => void;
  reset: () => void;
}

export const usePublicChatStore = create<PublicChatState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        messages: [],
        categories: [],
        rateLimitMessagesIndex: null,
        selectCategories: (categories) => set({ categories }),
        addMessage: (data, options) =>
          set((state) => {
            const now = new Date();
            return {
              rateLimitMessagesIndex: options?.rateLimited
                ? state.messages.length
                : null,
              messages: [
                ...state.messages,
                {
                  ...data,
                  createdAt: now,
                },
              ],
            };
          }),
        reset: () => set({ messages: [] }),
      }),
      { name: "public-chat" }
    )
  )
);
