import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  type ModalProps,
} from "@chakra-ui/react";
import type { DocumentCategory } from "@prisma/client";
import React, { useCallback } from "react";
import { Bookmark } from "react-feather";
import { useForm } from "react-hook-form";
import { CategoriesCheckboxGroup } from "~/components/molecules/CategoriesCheckboxGroup";

const FORM_ID = "categories";

interface SelectCategoriesModalProps extends Omit<ModalProps, "children"> {
  isLoading?: boolean;
  onSelect: (categories: DocumentCategory[]) => unknown;
}

export const SelectCategoriesModal: React.FC<SelectCategoriesModalProps> = ({
  isLoading,
  onSelect,
  ...props
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    clearErrors,
    handleSubmit,
  } = useForm<{
    [key in DocumentCategory]: boolean;
  }>();

  const isInvalid = Object.values(errors).length > 0;

  const onSubmit = handleSubmit((data) => {
    const selectedCategories = Object.entries(data).reduce<DocumentCategory[]>(
      (acc, [key, value]) => {
        if (value) acc.push(key as DocumentCategory);
        return acc;
      },
      []
    );

    onSelect(selectedCategories);
  });

  const validate = useCallback(() => {
    clearErrors();
    const values = getValues();
    const isValid = Object.values(values).some((v) => v);
    return isValid;
  }, [clearErrors, getValues]);

  const onClose = () => {
    onSelect([]);
  };

  return (
    <Modal isCentered {...props} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack spacing={4}>
            <Icon as={Bookmark} fontSize="5xl" color="blue.600" />
            <Heading fontSize="xl">Vælg kategorier</Heading>
          </Stack>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody pt={4} pb={8}>
          <Stack as="form" id={FORM_ID} onSubmit={onSubmit}>
            <FormControl as="fieldset" isInvalid={isInvalid}>
              <FormLabel as="legend">
                I hvilken egenskab bruger du SkatGPT?
              </FormLabel>
              <CategoriesCheckboxGroup
                control={control}
                validate={validate}
                isDisabled={isLoading}
              />
              <FormErrorMessage>
                Du skal vælge mindst én kategori
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            form={FORM_ID}
            isLoading={isLoading}
            isDisabled={isInvalid}
          >
            Gem
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
