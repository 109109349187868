import { Heading, Text, type HeadingProps } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";

interface TitleProps extends HeadingProps {}

export const Title: React.FC<TitleProps> = ({ ...props }) => {
  return (
    <Link href="/">
      <Heading as="h1" fontSize="2xl" {...props}>
        <Text as="span" fontWeight="bold">
          Skat
        </Text>
        <Text as="span" fontWeight="normal">
          GPT
        </Text>
      </Heading>
    </Link>
  );
};
