import { Box, Button, Spinner, Stack, type BoxProps } from "@chakra-ui/react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { ChatListItem } from "~/components/organisms/ChatListItem";
import { ChatListSupport } from "~/components/organisms/ChatListSupport";
import { useAuthContext } from "~/context/AuthContext";
import { useChatId } from "../../hooks/use-chat-id";
import { api } from "../../utils/api";

interface ChatListProps extends BoxProps {
  isCreatingChat?: boolean;
}

export const ChatList: React.FC<ChatListProps> = ({
  isCreatingChat,
  ...props
}) => {
  const { isAuthenticated, signIn } = useAuthContext();
  const chatId = useChatId();
  const chats = api.chat.list.useQuery(undefined, { enabled: isAuthenticated });
  const router = useRouter();
  const deleteConversation = api.chat.delete.useMutation({
    async onSuccess() {
      await router.push("/");
      void chats.refetch();
    },
  });
  const changeName = api.chat.update.useMutation({
    onSuccess() {
      void chats.refetch();
    },
  });

  const [listRef] = useAutoAnimate();

  return (
    <Stack fontSize="sm" overflowX="hidden" spacing={4} {...props}>
      {isAuthenticated && (chats.isLoading || isCreatingChat) ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          <Button
            flexShrink={0}
            as={Link}
            href={`/`}
            onClick={isAuthenticated ? undefined : signIn}
          >
            Ny samtale
          </Button>
          <Stack as="ul" listStyleType="none" ref={listRef}>
            {chats.data?.map((chat) => (
              <ChatListItem
                key={chat.id}
                chat={chat}
                isActive={chat.id === chatId}
                onChangeName={(name) =>
                  changeName.mutate({ id: chat.id, name })
                }
                onDelete={() => deleteConversation.mutate({ id: chat.id })}
              />
            ))}
            {isAuthenticated ? (
              <Box as="li" key="support">
                <ChatListSupport isActive={router.pathname === "/adam"} />
              </Box>
            ) : null}
          </Stack>
        </>
      )}
    </Stack>
  );
};
