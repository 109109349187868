import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  type ModalProps,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { signIn } from "next-auth/react";
import { usePlausible } from "next-plausible";
import router from "next/router";
import React from "react";
import { Mail, Send } from "react-feather";
import { useForm } from "react-hook-form";
import { z } from "zod";

interface LoginModalProps extends Omit<ModalProps, "children"> {}

export const LoginModal: React.FC<LoginModalProps> = ({ ...props }) => {
  const plausible = usePlausible();
  const mutation = useMutation<void, unknown, { email: string }>(
    async ({ email }) => {
      await signIn("email", {
        email,
        redirect: false,
        callbackUrl: (router.query.callbackUrl as string) ?? "/",
      });
    }
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>();

  const onSubmit = handleSubmit(({ email }) => {
    plausible("login:send-link", { props: { emailLength: email.length } });
    mutation.mutate({ email });
  });

  const canCloseModal = mutation.isIdle || mutation.isError;

  return (
    <Modal
      isCentered
      closeOnEsc={canCloseModal}
      closeOnOverlayClick={canCloseModal}
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Stack spacing={4}>
            <Icon
              as={mutation.isSuccess ? Send : Mail}
              fontSize="5xl"
              color="blue.600"
            />
            <Heading fontSize="xl">
              {mutation.isSuccess ? "Et link er på vej" : "Log ind"}
            </Heading>
          </Stack>
          <ModalCloseButton isDisabled={!canCloseModal} />
        </ModalHeader>
        <ModalBody pt={4} pb={8}>
          {mutation.isSuccess ? (
            <Stack spacing={4}>
              <Text>
                Hvis den ikke dukker op i indbakken, tag et ekstra tjek i
                spamfolderen
              </Text>
            </Stack>
          ) : (
            <Stack
              as="form"
              spacing={4}
              listStyleType="none"
              onSubmit={onSubmit}
            >
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>E-post</FormLabel>
                <Input
                  type="email"
                  placeholder="adam@exempel.dk"
                  {...register("email", {
                    validate: (value) => {
                      const parsed = z.string().email().safeParse(value);
                      return parsed.success || "Ugyldigt format";
                    },
                    required: true,
                  })}
                  isDisabled={mutation.isLoading}
                />
                <FormErrorMessage>
                  {errors.email?.message || "Ugyldigt format"}
                </FormErrorMessage>
                <FormHelperText>
                  Indtast din e-mailadresse, så sender vi et link til dig, som
                  du kan klikke videre på
                </FormHelperText>
              </FormControl>
              <Button
                type="submit"
                isLoading={mutation.isLoading}
                rightIcon={<Icon as={Send} />}
              >
                Send link
              </Button>
            </Stack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
