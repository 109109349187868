import type { UseDisclosureReturn } from "@chakra-ui/react";
import { createContext, useContext } from "react";

export const DisclosureContext = createContext<{
  selectCategoriesModal: UseDisclosureReturn;
}>({
  selectCategoriesModal: {} as UseDisclosureReturn,
});

export const useDisclosureContext = () => useContext(DisclosureContext);
