import { HStack, Icon, Stack, Text, type BoxProps } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";
import { ChevronRight } from "react-feather";

interface ChatListSupportProps extends BoxProps {
  isActive?: boolean;
}

export const ChatListSupport: React.FC<ChatListSupportProps> = ({
  isActive,
  ...props
}) => {
  return (
    <HStack
      flex={1}
      as={Link}
      href={`/adam`}
      bg={isActive ? "yellow.200" : "yellow.100"}
      border="1px solid"
      borderColor="yellow.700"
      borderRadius="md"
      color="blue.900"
      justify="space-between"
      align="center"
      p={3}
      transition="all 0.2s"
      _hover={{
        bg: "yellow.200",
      }}
      {...props}
    >
      <Text as="span" noOfLines={1}>
        Chat med udvikleren
      </Text>
      <Stack px={1}>
        <Icon as={ChevronRight} />
      </Stack>
    </HStack>
  );
};
