import {
  Box,
  Container,
  HStack,
  Link,
  Text,
  type BoxProps,
} from "@chakra-ui/react";
import React from "react";

interface BannerProps extends BoxProps {}

export const Banner: React.FC<BannerProps> = () => {
  return (
    <Box bg="black" color="white" py={2}>
      <Container>
        <HStack justify="center" spacing={3}>
          <Text>🚀</Text>
          <Text
            fontSize={["md", null, "lg"]}
            fontWeight="bold"
            textAlign={["left", null, "center"]}
          >
            SkatGPT er gratis at bruge!{` `}
            <Link
              color="blue.500"
              textDecoration="underline"
              href="https://adamalfredsson.se/"
              target="_blank"
            >
              Interesseret i jeres egen chatbot?
            </Link>
          </Text>
        </HStack>
      </Container>
    </Box>
  );
};
