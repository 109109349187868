import {
  Checkbox,
  CheckboxGroup,
  Stack,
  type CheckboxGroupProps,
} from "@chakra-ui/react";
import { DocumentCategory } from "@prisma/client";
import React from "react";
import { Controller, type Control } from "react-hook-form";

interface CategoriesCheckboxGroupProps extends CheckboxGroupProps {
  control: Control<Record<DocumentCategory, boolean>>;
  validate?: (value: boolean) => boolean;
}

export const CategoriesCheckboxGroup: React.FC<
  CategoriesCheckboxGroupProps
> = ({ control, validate, ...props }) => {
  return (
    <CheckboxGroup {...props}>
      <Stack>
        {Object.values(DocumentCategory).map((category) => (
          <Controller
            key={category}
            control={control}
            name={category}
            rules={{
              validate,
            }}
            render={({ field }) => (
              <Checkbox
                isChecked={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                ref={field.ref}
              >
                {
                  {
                    [DocumentCategory.borger]: "Borger",
                    [DocumentCategory.erhverv]: "Erhverv",
                  }[category]
                }
              </Checkbox>
            )}
          />
        ))}
      </Stack>
    </CheckboxGroup>
  );
};
