import {
  Box,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  type BoxProps,
} from "@chakra-ui/react";
import type { Chat } from "@prisma/client";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import React from "react";
import { ChevronRight, Edit3, Settings, Trash } from "react-feather";
import { CHAT_ID_QUERY_PARAM } from "~/hooks/use-chat-id";

interface ChatListItemProps extends BoxProps {
  chat: Chat;
  onChangeName: (name: string) => void;
  onDelete: () => void;
  isActive?: boolean;
}

export const ChatListItem: React.FC<ChatListItemProps> = ({
  chat,
  isActive,
  onChangeName,
  onDelete,
  ...props
}) => {
  const openNamePrompt = () => {
    const name = prompt("Ange ett nytt namn", chat.name);
    if (name) {
      onChangeName(name);
    }
  };

  return (
    <HStack as="li" position="relative" {...props}>
      <HStack
        flex={1}
        as={Link}
        href={`/?${CHAT_ID_QUERY_PARAM}=${chat.id}`}
        bg={isActive ? "blue.100" : "blue.50"}
        border="1px solid"
        borderColor={isActive ? "blue.300" : "blue.400"}
        borderRadius="md"
        color="blue.900"
        justify="space-between"
        align="center"
        p={3}
        pr={8}
        transition="all 0.2s"
        pointerEvents={isActive ? "none" : "auto"}
        _hover={{
          bg: "blue.100",
        }}
      >
        <Text as="span" noOfLines={1}>
          {chat.name}
        </Text>
      </HStack>
      <HStack position="absolute" insetY={0} right={3} pointerEvents="none">
        <AnimatePresence mode="wait">
          {isActive ? (
            <Box
              as={motion.div}
              key="menu"
              position="relative"
              initial={{ opacity: 0, left: -8 }}
              animate={{ opacity: 1, left: 0 }}
              exit={{ opacity: 0, left: 4 }}
              pointerEvents="auto"
            >
              <Menu>
                <MenuButton
                  as={IconButton}
                  variant="ghost"
                  aria-label="Meny"
                  size="xs"
                  icon={<Icon as={Settings} fontSize="sm" />}
                  color="blue.900"
                  bg="transparent"
                />
                <MenuList color="blue.700">
                  <MenuItem
                    icon={
                      <Stack>
                        <Icon as={Edit3} fontSize="md" />
                      </Stack>
                    }
                    onClick={() => openNamePrompt()}
                  >
                    Ändra namn
                  </MenuItem>
                  <MenuItem
                    icon={
                      <Stack>
                        <Icon as={Trash} fontSize="md" />
                      </Stack>
                    }
                    color="red"
                    onClick={() => onDelete()}
                  >
                    Ta bort konversation
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          ) : (
            <Stack
              as={motion.div}
              key="chevron"
              position="relative"
              px={1}
              initial={{ opacity: 0, left: 8 }}
              animate={{ opacity: 1, left: 0 }}
              exit={{ opacity: 0, left: 8 }}
            >
              <Icon as={ChevronRight} />
            </Stack>
          )}
        </AnimatePresence>
      </HStack>
    </HStack>
  );
};
