import {
  Badge,
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Heading,
  Icon,
  IconButton,
  Stack,
  StackDivider,
  useDisclosure,
  type BoxProps,
} from "@chakra-ui/react";
import type { DocumentCategory } from "@prisma/client";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { usePlausible } from "next-plausible";
import { default as NextLink } from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { HelpCircle, LogIn, Menu as MenuIcon, Settings } from "react-feather";
import { Title } from "~/components/atoms/Title";
import { SelectCategoriesModal } from "~/components/modals/SelectCategoriesModal";
import { Banner } from "~/components/organisms/Banner";
import { AuthContext } from "~/context/AuthContext";
import { DisclosureContext } from "~/context/DisclosureContext";
import { CHAT_ID_QUERY_PARAM } from "~/hooks/use-chat-id";
import { usePublicChatStore } from "~/store/chat";
import { api } from "~/utils/api";
import { LoginModal } from "../modals/LoginModal";
import { ChatList } from "../organisms/ChatList";
import { Layout } from "./Layout";

interface LayoutProps extends BoxProps {
  isAuthenticated: boolean;
}

export const ChatLayout: React.FC<LayoutProps> = ({
  isAuthenticated,
  children,
}) => {
  const plausible = usePlausible();
  const {
    messages: persistedMessages,
    categories: persistedCategories,
    rateLimitMessagesIndex,
    selectCategories: persistCategories,
    reset: resetPersisted,
  } = usePublicChatStore();
  const categoriesModal = useDisclosure();
  const loginModal = useDisclosure();
  const menuDrawer = useDisclosure();
  const router = useRouter();
  const queryClient = useQueryClient();

  const createChat = api.chat.create.useMutation({
    async onSuccess(data) {
      await queryClient.invalidateQueries(getQueryKey(api.chat.list));
      void router.push(`?${CHAT_ID_QUERY_PARAM}=${data.chatId}`);
      resetPersisted();
    },
  });

  api.user.getMe.useQuery(undefined, {
    enabled: isAuthenticated,
    onSuccess(data) {
      if (data.categories.length === 0) {
        if (persistedCategories.length) {
          selectCategories.mutate({ categories: persistedCategories });
          return;
        }
        categoriesModal.onOpen();
      }
      if (persistedMessages.length && !createChat.isLoading) {
        const messagesUntilRateLimit =
          rateLimitMessagesIndex != null
            ? persistedMessages.slice(0, rateLimitMessagesIndex + 1)
            : persistedMessages;
        createChat.mutate({
          messages: messagesUntilRateLimit,
        });
      }
    },
  });

  const selectCategories = api.user.selectCategories.useMutation({
    onSuccess() {
      categoriesModal.onClose();
      void queryClient.invalidateQueries(getQueryKey(api.user.getMe));
    },
  });

  const onCategoriesSelect = (categories: DocumentCategory[]) => {
    plausible("categories:select", { props: { categories } });
    if (isAuthenticated) {
      selectCategories.mutate({ categories });
    } else {
      persistCategories(categories);
      categoriesModal.onClose();
    }
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", menuDrawer.onClose);
    return () => {
      router.events.off("routeChangeComplete", menuDrawer.onClose);
    };
  }, [router.events, menuDrawer]);

  const authContext = useMemo(
    () => ({
      isAuthenticated,
      signIn: () => loginModal.onOpen(),
    }),
    [isAuthenticated, loginModal]
  );

  return (
    <Layout>
      <AuthContext.Provider value={authContext}>
        <DisclosureContext.Provider
          value={{
            selectCategoriesModal: categoriesModal,
          }}
        >
          <Stack h="full" spacing={0}>
            <Banner />
            <Stack
              flex={1}
              direction={["column", null, "row"]}
              alignItems="stretch"
              overflow="hidden"
            >
              <Box
                as="header"
                flex={[0, null, 1]}
                maxW={["none", null, "xs"]}
                bg="blue.50"
                color="blue.700"
                py={4}
              >
                <Container display={["none", null, "block"]} h="full">
                  <Stack
                    justify="space-between"
                    h="full"
                    divider={<StackDivider borderColor="blue.900" />}
                    spacing={4}
                  >
                    <Stack overflow="hidden" flex={1}>
                      <HStack mb={8} justify="space-between">
                        <Title />
                        <Badge colorScheme="yellow" fontSize="md" px={2}>
                          2024
                        </Badge>
                      </HStack>
                      <ChatList
                        flex={1}
                        overflowY="auto"
                        isCreatingChat={createChat.isLoading}
                      />
                    </Stack>
                    <Stack>
                      <Button
                        variant="menu"
                        as={NextLink}
                        href="/om"
                        leftIcon={<Icon as={HelpCircle} />}
                      >
                        Om SkatGPT
                      </Button>
                      {isAuthenticated ? (
                        <Button
                          variant="menu"
                          as={NextLink}
                          href="/profile"
                          leftIcon={<Icon as={Settings} />}
                        >
                          Indstillinger
                        </Button>
                      ) : (
                        <Button
                          variant="menu"
                          onClick={() => loginModal.onOpen()}
                          leftIcon={<Icon as={LogIn} />}
                        >
                          Log ind
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Container>
                <Container display={["block", null, "none"]}>
                  <HStack justify="space-between">
                    <Title />
                    <IconButton
                      onClick={menuDrawer.onOpen}
                      aria-label="Menu"
                      icon={<Icon as={MenuIcon} />}
                    />
                  </HStack>
                </Container>
              </Box>
              <Stack as="main" position="relative" overflowY="auto" flex={3}>
                {children}
              </Stack>
            </Stack>
          </Stack>
          <LoginModal isOpen={loginModal.isOpen} onClose={loginModal.onClose} />
          <SelectCategoriesModal
            isLoading={selectCategories.isLoading}
            isOpen={categoriesModal.isOpen}
            onClose={categoriesModal.onClose}
            onSelect={onCategoriesSelect}
          />
          <Drawer isOpen={menuDrawer.isOpen} onClose={menuDrawer.onClose}>
            <DrawerOverlay />
            <DrawerContent bg="white" color="blue.700">
              <DrawerHeader>
                <Heading fontSize="2xl">Samtaler</Heading>
                <DrawerCloseButton />
              </DrawerHeader>
              <DrawerBody>
                <Stack h="full">
                  {isAuthenticated ? (
                    <ChatList flex={1} overflowY="auto" />
                  ) : null}
                </Stack>
              </DrawerBody>
              <DrawerFooter justifyContent="flex-start">
                <Stack>
                  <Button
                    variant="menu"
                    as={NextLink}
                    href="/om"
                    leftIcon={<Icon as={HelpCircle} />}
                  >
                    Om SkatGPT
                  </Button>
                  {isAuthenticated ? (
                    <Button
                      variant="menu"
                      as={NextLink}
                      href="/profile"
                      leftIcon={<Icon as={Settings} />}
                    >
                      Indstillinger
                    </Button>
                  ) : (
                    <Button
                      variant="menu"
                      onClick={() => {
                        loginModal.onOpen();
                        menuDrawer.onClose();
                      }}
                      leftIcon={<Icon as={LogIn} />}
                    >
                      Log ind
                    </Button>
                  )}
                </Stack>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </DisclosureContext.Provider>
      </AuthContext.Provider>
    </Layout>
  );
};
