import { createContext, useContext } from "react";

export const AuthContext = createContext<{
  isAuthenticated: boolean;
  signIn: () => void;
}>({
  isAuthenticated: false,
  signIn: () => {
    throw new Error("signIn not implemented");
  },
});

export const useAuthContext = () => useContext(AuthContext);
